window.players = []

function loadYoutube() {
    // Load the IFrame Player API code asynchronously.
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Replace the 'ytplayer' element with an <iframe> and
    // YouTube player after the API code downloads.
    var player;
    var apiHasLoaded = false;
    window.onYouTubePlayerAPIReady = function () {
        apiHasLoaded = true;
        var youtubeplayers = document.querySelectorAll('.video-player');
        var youtubeContainers = document.querySelectorAll(".video-container");

        for (var i = 0; i < youtubeplayers.length; i++) {

            var jsonvideo = youtubeplayers[i].dataset.video;
            var parsedjsonvideo = JSON.parse(jsonvideo);
            var youtubeIndex = 0;

            if (typeof YT === 'undefined') {
                return;
            }

            player = new YT.Player(
                parsedjsonvideo["videoid"],
                {
                    height: '0',
                    width: '0',
                    videoId: parsedjsonvideo["videoid"],
                    playerVars: {
                        'autoplay': parsedjsonvideo["autoplay"],
                        'controls': parsedjsonvideo["showcontrols"],
                        'fs': parsedjsonvideo["showfullscreenoption"],
                        'loop': parsedjsonvideo["loop"],
                        'modestbranding': parsedjsonvideo["modestbranding"],
                        'rel': parsedjsonvideo["showrelatedcontent"],
                        'mute': parsedjsonvideo["mute"],
                        'playsinline': 1
                    },
                    events: {
                        'onReady': function (event) {
                            youtubeContainers[youtubeIndex].classList.remove("no-video");
                            youtubeIndex++;

                            if (JSON.parse(event.target.getIframe().dataset.video).mute === 1) {
                                event.target.mute();

                                if (JSON.parse(event.target.getIframe().dataset.video).autoplay === 1) {
                                    event.target.playVideo();
                                }
                            }
                        },
                        'onStateChange': function (event) {
                            if (event.data === YT.PlayerState.ENDED) {
                                event.target.playVideo();
                            }
                            if (event.data === YT.PlayerState.BUFFERING) {
                                setTimeout(function () {
                                    if (event.target.getPlayerState() === YT.PlayerState.BUFFERING) {
                                        event.target.stopVideo();
                                        youtubeContainers[youtubeIndex].innerHTML = "<p>Sorry, the video could not be loaded. </p>";
                                    }
                                }, 3000);
                            } else if (event.data === YT.PlayerState.CUED) {
                                event.target.playVideo();
                            }
                        },
                        'onError': function (event) {
                            const errorCode = event.data;

                            if (errorCode === 100 || errorCode === 101 || errorCode === 150) {

                            }
                        },
                    },
                });
            window.players.push({ytPlayerId: parsedjsonvideo["videoid"], player: player})
        }
    }

    if (!apiHasLoaded) {
        window.onYouTubePlayerAPIReady();
    }
}
loadYoutube();